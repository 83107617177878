import Axios from 'axios';
import CONSTANTS from './CONSTANTS';
import moment from 'moment-timezone';

const {AUTH_HEADERS, API_APPOINTMENTS_OPEN_URL, API_DEPARTMENTS_URL, API_PROVIDERS_URL, API_REASONS_URL, API_REASONS_NEW_URL, API_REASONS_EXISTING_URL, API_TYPES_URL, API_SET_APPOINTMENT_BOOK_URL, API_SET_PATIENT_APPOINTMENT_URL, BASE_URL, DEPARTMENT_ID, PROVIDERIDFORAPTS} = CONSTANTS;

/*
* Get Methods
*/

/* exported getAppointments */
export async function getAppointments (options) {
    const {bypassscheduletimechecks, endDate, ignoreschedulablepermission, startDate, location, reasons, showfrozenslots, providersId} = options;
    const endpointParams = `?reasonid=${reasons}&bypassscheduletimechecks=${bypassscheduletimechecks}&ignoreschedulablepermission=${ignoreschedulablepermission}&providerid=${providersId ? providersId : PROVIDERIDFORAPTS}&showfrozenslots=${showfrozenslots}&startdate=${startDate}&enddate=${endDate}&departmentid=${location ? location : DEPARTMENT_ID}`;
    const updatedAPILink = `${API_APPOINTMENTS_OPEN_URL}${endpointParams}`;
    return await Axios.get(updatedAPILink, AUTH_HEADERS);
}

/* exported getConfig */
export async function getConfig () {
    const endpointParams = '/scheduling/config/vmd-tx-iah/villagefamilypractice';
    const updatedAPILink = `${BASE_URL}${endpointParams}`;
    return await Axios.get(updatedAPILink);
}

/* exported getDepartments */
export async function getDepartments () {
    const endpointParams = '?providerlist=true&showalldepartments=false';
    const updatedAPILink = `${API_DEPARTMENTS_URL}${endpointParams}`;
    return await Axios.get(updatedAPILink, AUTH_HEADERS);
}

/* exported getProviders */
export async function getProviders () {
    const updatedAPILink = `${API_PROVIDERS_URL}`;
    return await Axios.get(updatedAPILink, AUTH_HEADERS);
}

/* exported getAppointmentReasons */
export async function getAppointmentReasons (locationId, providerId, firstTimePatient) {
    let endpoint = API_REASONS_URL;
    
    if (firstTimePatient && firstTimePatient === 'true') {
        endpoint =  API_REASONS_NEW_URL;
    } else if (firstTimePatient && firstTimePatient === 'false') {
        endpoint = API_REASONS_EXISTING_URL;
    }

    const locationParam = locationId ? `departmentid=${locationId}` : '';
    const providerParam = providerId ? `&providerid=${providerId}` : '';
    const endpointParams = `?${locationParam}${providerParam}`;
    const updatedAPILink = `${endpoint}${endpointParams}`;
    return await Axios.get(updatedAPILink, AUTH_HEADERS);
}

/* exported getAppointmentTypes */
export async function getAppointmentTypes () {
    const endpointParams = '?hidegeneric=false&hidenongeneric=false&hidenonpatient=true&hidetemplatetypeonly=false';
    const updatedAPILink = `${API_TYPES_URL}${endpointParams}`;
    return await Axios.get(updatedAPILink, AUTH_HEADERS);
}

/*
* Post Methods
*/

/* exported bookAppointment */
export async function bookAppointment (appointmentId, appointmenttypeid, appointmentNotes, patientId, appointmentInsurance) {
    const endpointParams = `/${appointmentId}`;
    const updatedAPILink = `${API_SET_APPOINTMENT_BOOK_URL}${endpointParams}`;
    const updatedHeaders = AUTH_HEADERS.headers;
    updatedHeaders['content-type'] = 'application/x-www-form-urlencoded';

    let data = {
        appointmenttypeid,
        bookingnote: appointmentNotes,
        ignoreschedulablepermission: false,
        insurancenote: appointmentInsurance,
        patientid: patientId
    };

    return await Axios({
        method: 'put',
        url: updatedAPILink,
        params: data,
        headers: updatedHeaders
    });
}

/* exported setPatientAppointment */
export async function setPatientAppointment (appointment, userContactEntity, departmentId, appointmentInsurance, appointmentNotes) {

    const {appointmentid, appointmenttypeid, providerid} = appointment;
    const {address, address2, city, consentForm, dob, email, firstName, lastName, phoneNumber, phoneNumberType, state, zip, sex} = userContactEntity;
    const updatedAPILink = `${API_SET_PATIENT_APPOINTMENT_URL}`;
    const updatedHeaders = AUTH_HEADERS.headers;
    
    updatedHeaders['content-type'] = 'application/x-www-form-urlencoded';

    const data = {
        appointmentid,
        appointmenttypeid,
        address1: address,
        address2,
        city,
        dob,
        email,
        firstname: firstName,
        lastname: lastName,
        state,
        zip,
        sex,
        departmentid: departmentId,
        bookingnote: appointmentNotes,
        ignoreschedulablepermission: false,
        insurancenote: appointmentInsurance,
    };

    if (phoneNumberType === 'Home') {
        data['homephone'] = phoneNumber;
    }
    if (phoneNumberType === 'Mobile') {
        data['mobilephone'] = phoneNumber;
    }

    // Pass in values set to true when a person approves and consents
    if (consentForm === true) {
        data['consenttocall'] = 'true';
        data['consenttotext'] = 'true';
        data['privacynotice'] = 'true';
        data['patientsignature'] = 'true';
        data['signaturedatetime'] = moment().format('MM/DD/YYYY, HH:mm:ss');
        data['signaturename'] = `${firstName} ${lastName}`;
    }

    data['primaryproviderid'] = providerid;

    let updatedData = Object.keys(data).map((key) => `${key}=${data[`${key}`]}`).join('&');

    return await Axios({
        method: 'post',
        url: updatedAPILink, 
        data: updatedData,
        headers: updatedHeaders
    });
}
