import React from 'react';

import {getAppointmentLocalTime} from '../../utilities';

const BookAppointmentLink = (props) => {
    const {appointmentid, date, classNames, onBookAppointment, starttime, department} = props;
    const {timezonename} = department;

    const localHourMinuteDate = getAppointmentLocalTime(date, starttime, timezonename);

    return (
        starttime ? (
            <button
                className={`button is-small ${classNames}`}
                data-test="book_appointment_link_button"
                onClick={() => {
                    appointmentid && onBookAppointment(appointmentid)
                }}> {localHourMinuteDate}</button>
        ) : null
    );
};

export default BookAppointmentLink;
