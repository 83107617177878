import React from 'react';

import BookAppointmentSelectedAppointment from '../BookAppointmentSelectedAppointment/book_appointment_selected_appointment';

import './book_appointment_confirm.scss';

const BookAppointmentConfirm = (props) => {
    let appointmentInsurance = 'Self Pay DTC';
    const {appointment, appointmentsFilter, appointmentNotes, departments,
        handleAppointmentDataUpdates, handleBookAppointment, handleDismissAppointment,
        handleEditSection, reasons, setLocationId, providers, user, title, handleUserInputChange
    } = props;

    return(
        <div className="vmd-book-appointment-confirm">
            <h3 className="title">{title}</h3>
            <span className="subtitle required-text">*Fields are required</span>
            <hr />
            <div>
                <h4 className="title">Appointment details</h4>
                {
                    <BookAppointmentSelectedAppointment
                        appointmentsFilter={appointmentsFilter}
                        departments={departments}
                        handleDismissAppointment={handleDismissAppointment}
                        reasons={reasons}
                        appointment={appointment}
                        setLocationId={setLocationId}
                        providers={providers}
                        headline='Selected Appointment:' />
                }
            </div>
            <hr />
            <div data-test="patient-information-section">
                <span>
                    <span
                        className="edit-section is-pulled-right"
                        data-test="edit-patient-details"
                        onClick={() => {
                            handleEditSection();
                        }}>Edit</span>
                    <h4 className="title">Patient information</h4>
                </span>
                <br />
                <div className="columns">
                    <div className="column">
                        <p data-test="patient-information-name">Name: {user.firstName} {user.lastName}</p>
                        <p data-test="patient-information-email">Email: {user.email}</p>
                        <p data-test="patient-information-dob">DOB: {user.dob}</p>
                        <p data-test="patient-information-sex">Sex: {user.sex}</p>
                        <p data-test="patient-information-phone">Contact number: {user.phoneNumber} - Phone Type: {user.phoneNumberType}</p>
                    </div>
                    <div className="column">
                        <p data-test="patient-information-address">Address: {user.address} {user.address2}</p>
                        <p data-test="patient-information-city">City: {user.city}</p>
                        <p data-test="patient-information-state">State: {user.state}</p>
                        <p data-test="patient-information-zip">ZIP: {user.zip}</p>
                    </div>
                </div>
                {
                    (user.guardCheck && user.showGuardianCheck) && (
                        <div className="columns">
                            <div className="column">
                                <span data-test="patient-information-guardian">Patient has certified parent or legal guardian.</span>
                            </div>
                        </div>   
                    )
                }
            </div>
            <hr />
            <div className="field checkbox">
                <label className="label">
                    <input
                        type="checkbox"
                        name={'consentForm'}
                        onChange={(e) => {
                            handleUserInputChange(e);
                        }}
                        checked={user.consentForm} />
                    <span className="checkbox-links">I have been given an opportunity to ask questions, and all my questions have been answered fully and satisfactorily. I certify that this <a href="https://www.villagemedical.com/virtual-informed-consent" target="_blank" rel="noopener noreferrer">Informed Consent for Virtual Care Services</a> has been fully explained to me, that I have read it or have had it read to me, and that I understand its contents. I voluntarily consent to health care services provided to me via Virtual Care.</span>
                </label>
                <label className="label">
                    <input
                        type="checkbox"
                        name={'privacyForm'}
                        onChange={(e) => {
                            handleUserInputChange(e);
                        }}
                        checked={user.privacyForm} />
                    <span>I acknowledge that I have reviewed and consent to the physicians' <a href="https://www.villagemedical.com/notice-privacy-practices" target="_blank" rel="noopener noreferrer">privacy practices</a>.</span>
                </label>
            </div>
            <hr />
            <div className="field is-hidden">
                <label className="label">Patient Insurance:*</label>
                <textarea
                    className="textarea"
                    data-test="patient-insurance"
                    name="appointmentInsurance"
                    onChange={(e) => {
                        handleAppointmentDataUpdates(e);
                    }}
                    placeholder="Insurance Notes"
                    value={appointmentInsurance}/>
            </div>
            <div className="field">
                <label className="label">Additional Notes:</label>
                <textarea
                    className="textarea"
                    data-test="additional-notes"
                    name="appointmentNotes"
                    onChange={(e) => {
                        handleAppointmentDataUpdates(e);
                    }}
                    placeholder="Tell us more about the reason for your visit"
                    value={appointmentNotes}/>
            </div>
            <hr />
            <button
                className="button is-info is-pulled-right is-primary-button"
                data-test="confirm-appointment-button"
                disabled={(appointmentInsurance === '' || !user.consentForm || !user.privacyForm )}
                onClick={() => {
                    handleBookAppointment();
                }}>Book Appointment</button>
        </div>
    );
};

export default BookAppointmentConfirm;
