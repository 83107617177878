import React from 'react';

import loader  from '../../component-assets/svg/loading.svg';

import './book_appointment_loading_status.scss';

const BookAppointmentLoadingStatus = () => {
    return (
        <div className="vmd-book-appointment-loading-status is-flex">
            <img src={loader} alt="Loading..."/>
        </div>
    );
};

export default BookAppointmentLoadingStatus;
