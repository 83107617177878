import React from 'react';
import moment from 'moment-timezone';

import './book_appointment_component.scss';
import bandaidIcon from '../../component-assets/svg/bandaid.svg';
import refreshIcon from '../../component-assets/svg/refresh.svg';

import CONSTANTS from '../../CONSTANTS';

import {getAppointments, getAppointmentTypes, getAppointmentReasons, getConfig, getDepartments, getProviders, setPatientAppointment} from '../../API';
import {getProviderDetails} from '../../HUBSPOTAPI';

import BookAppointmentList from '../BookAppointmentList/book_appointment_list';
import BookAppointmentUserDetails from '../BookAppointmentUserDetails/book_appointment_user_details.js';
import BookAppointmentConfirm from '../BookAppointmentConfirm/book_appointment_confirm';
import BookAppointmentSuccess from '../BookAppointmentSuccess/book_appointment_success';
import BookAppointmentLoadingStatus from '../BookAppointmentLoadingStatus/book_appointment_loading_status';

const {EXISTING_PATIENT_DEFAULT_REASON, NEW_PATIENT_DEFAULT_REASON, ERROR_CODES} = CONSTANTS;
const MOBILEDAYSAHEAD = 2;
const DESKTOPDAYSAHEAD = 5;
const MOBILEMAXWIDTH = 500;
const WINDOWRESIZEMILLISECONDDEFER = 500;
const SCROLLMILLISECONDDEFER = 500;

class BookAppointmentComponent extends React.Component {
    constructor (props) {
        super (props);

        this.state = {
            appointments: [],
            appointmentsFilter: NEW_PATIENT_DEFAULT_REASON,
            appointmentNotes: '',
            appointmentInsurance: '',
            componentIsLoading: false,
            isLoading: true,
            isMobile: null,
            userContactEntity: {
                address:'',
                address2: '',
                consentForm: '',
                privacyForm: false,
                city: '',
                dob: '',
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                phoneNumberType: '',
                guardCheck: false,
                showGuardianCheck: false,
                state: '',
                zip: '',
                sex: ''
            },
            userContactEntityDirty: {
                addressDirty: false,
                address2Dirty: false,
                consentFormDirty: false,
                privacyFormDirty: false,
                cityDirty: false,
                dobDirty: false,
                emailDirty: false,
                firstNameDirty: false,
                lastNameDirty: false,
                phoneNumberDirty: false,
                phoneNumberTypeDirty: false,
                guardCheckDirty: false,
                stateDirty: false,
                zipDirty: false,
                sexDirty: false
            },
            userContactEntityErrors: {
                address:'',
                address2: '',
                city: '',
                dob: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                phoneNumberType: '',
                guardCheck: '',
                state: '',
                zip: '',
                sex: ''
            },
            userContactFormValid: null,
            responseError: '',
            setFirstTimePatient: 'true',
            selectedAppointmentId: null,
            step: '',
            setLocationId: 0,
            setProvidersId: 0,
            user: 'existing', // existing or new
            validUserId: '',
            validBooking: false,
            validBookedAppt: {},
            expandedDates: {},
            startDate: null,
            endDate: null,
            dateSpan: null,
            showDatePicker: false
        };
    }

    componentDidMount = () => {
        let scrollEventHandler;
        let resizeEventHandler;

        window.addEventListener('scroll', () => {
            /* jshint ignore:start */
            clearTimeout(scrollEventHandler);
            scrollEventHandler = setTimeout(this.handleScroll, SCROLLMILLISECONDDEFER);
            /* jshint ignore:end */
        });
        window.addEventListener('resize', () => {
            clearTimeout(resizeEventHandler);
            resizeEventHandler = setTimeout(this.getDimentions, WINDOWRESIZEMILLISECONDDEFER);
        });

        this.initializeComponent();
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.getDimentions);
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        this.toggleDatePicker();
    };

    toggleDatePicker = () => {
        const {showDatePicker} = this.state;
        const datePickerElement = document.querySelector('.react-datepicker');
        const isInViewport = (element) => {
            if (element) {
                const rect = element.getBoundingClientRect();
                const html = document.documentElement;
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || html.clientHeight) &&
                    rect.right <= (window.innerWidth || html.clientWidth)
                );
            } else {
                return false;
            }
        };

        if ((datePickerElement && isInViewport(datePickerElement)) !== showDatePicker) {
            this.setState({
                showDatePicker: datePickerElement && isInViewport(datePickerElement),
            });
        }
    };

    getDimentions = () => {
        const {endDate, isMobile, setLocationId, setProvidersId, startDate, dateSpan, selectedAppointmentId, validUserId} = this.state;

        let updatedIsMobile;
        let updatedEndDate;
        let updatedStartDate;
        let performGetDataQuery = false;
        let updatedDateSpan;

        updatedIsMobile = window.innerWidth < MOBILEMAXWIDTH;

        // Converting values when triggered from desktop to mobile
        if (updatedIsMobile === true && isMobile === false) {
            updatedDateSpan = MOBILEDAYSAHEAD;
            updatedStartDate = startDate ? moment(startDate).startOf('day').format('MM/DD/YYYY') : moment().startOf('day').format('MM/DD/YYYY');
            updatedEndDate = moment(updatedStartDate).endOf('day').format('MM/DD/YYYY');
            performGetDataQuery = true;
        } else if (updatedIsMobile === false && isMobile === true){
            // Converting from mobile to desktop
            updatedDateSpan = DESKTOPDAYSAHEAD;
            updatedStartDate = startDate ? moment(startDate).startOf('day').format('MM/DD/YYYY') : moment().startOf('day').format('MM/DD/YYYY');
            updatedEndDate = moment(updatedStartDate).endOf('day').add(DESKTOPDAYSAHEAD, 'days').format('MM/DD/YYYY');
            performGetDataQuery = true;
        }

        // If no endDate is set -- Used for initial page load
        if (!endDate) {
            let startDateToUse = startDate ? moment(startDate).startOf('day') : moment().startOf('day');

            updatedEndDate = updatedIsMobile ? startDateToUse.endOf('day').format('MM/DD/YYYY') : startDateToUse.add(DESKTOPDAYSAHEAD, 'days').endOf('day').format('MM/DD/YYYY');
        }

        // If no startdate is set -- Used for initial page load
        if (!startDate) {
            updatedStartDate = moment().startOf('day').format('MM/DD/YYYY');
        }

        // If no dateSpan is set --- Used for initial page load
        if (!dateSpan) {
            updatedDateSpan = updatedIsMobile ? MOBILEDAYSAHEAD : DESKTOPDAYSAHEAD;
        }

        this.setState({
            endDate: updatedEndDate ? updatedEndDate : endDate,
            isMobile: updatedIsMobile,
            dateSpan: updatedDateSpan ? updatedDateSpan : dateSpan,
            startDate: updatedStartDate ? updatedStartDate : startDate
        });

        // Only trigger a query if we are transitioning from mobile / desktop -- vice versa
        if (performGetDataQuery === true &&  !selectedAppointmentId && !validUserId) {
            this.getUpdatedDataWithConfig(null, setLocationId, setProvidersId, updatedStartDate ? updatedStartDate : startDate, updatedEndDate ? updatedEndDate : endDate);
        }
    };

    initializeComponent = async () => {
        const {setLocationId, setProvidersId, startDate, setFirstTimePatient} = this.state;
        const {queryStringValues} = this.props;
        const {parsedDate, parsedLocation, parsedProvider, parsedFirstTimePatient} = queryStringValues;
        let updatedParsedDate;
        let updatedParsedFirstTimePatient;

        if (parsedDate){
            let formattedParsedDate = moment(parsedDate, 'MMDDYYYY');
            let today = moment();
            updatedParsedDate = formattedParsedDate.isBefore(today) ? null : formattedParsedDate.startOf('day').format('MM/DD/YYYY');
        }

        if (parsedFirstTimePatient) {
            if (parsedFirstTimePatient.toLowerCase() === 'true' || parsedFirstTimePatient.toLowerCase() === 'false') {
                updatedParsedFirstTimePatient = parsedFirstTimePatient === 'true' ? 'true' : 'false';
            }
        }

        const configWasReturned = await this.getConfigData();
        this.getDimentions();
        await this.getUpdatedDataWithConfig(configWasReturned.configData, (setLocationId > 0) ? setLocationId : parsedLocation, (setProvidersId > 0) ? setProvidersId : parsedProvider, startDate ? startDate : updatedParsedDate, this.state.endDate, updatedParsedFirstTimePatient ? updatedParsedFirstTimePatient : setFirstTimePatient);
    };

    getConfigData = async () => {
        try {
            const configResponse = await getConfig();

            let optionsObj = {};

            if (configResponse.status === 200) {
                optionsObj = configResponse.data;
            }
            return {'usingConfig': true, 'configData':optionsObj};
        } catch (error) {
            let optionsObj = {};

            optionsObj = {
                '/appointments/open': {
                    'bypassscheduledtimechecks': 'false',
                    'ignoreschedulablepermission': 'false',
                    'numberOfDays': 7,
                    'showfrozenslots': 'false'
                },
                '/appointmenttypes': {
                    'hidegeneric': 'false',
                    'hidenongeneric': 'false',
                    'hidenonpatient': 'true',
                    'hidetemplatetypeonly': 'false'
                },
                '/departments': {
                    'providerlist': 'true',
                    'showalldepartments': 'false',
                    'defaultLocation': 1
                }
            };

            return {'usingConfig': false, 'configData': optionsObj};
        }
    };

    getUpdatedDataWithConfig = async (options, locationId, providersId, startDate, endDate, firstTimePatient) => {
        const {queryStringValues} = this.props;
        const {parsedHideHubspot} = queryStringValues;

        try {
            const {appointmentsFilter, optionsObj, setFirstTimePatient, setProvidersId} =  this.state;
            const optionsObjToUse = options !== null ? options : Object.assign({}, optionsObj);

            this.setState({
                componentIsLoading: true
            });

            // Dates
            const startDateToUse =  startDate || moment().startOf('day').format('MM/DD/YYYY');
            const endDateToUse = endDate || moment().endOf('day').format('MM/DD/YYYY');

            // Departments
            const departmentsResponse = await getDepartments();
            const departmentObjectToUse = await departmentsResponse.data.departments.find((department) => parseInt(department.departmentid, 10) === (locationId ? parseInt(locationId, 10) : optionsObjToUse['/departments']['defaultLocation']));

            // Providers
            const providersResponse = await getProviders();
            const allProviders = await providersResponse.data.providers.map((provider) => provider);
            const providersToUse = [...(providersId ? providersId.split(',') : departmentObjectToUse.providerlist)]; // we can pass in more providers - so long as we parse them out if they work in the department selected
            const validProvidersToUse = providersToUse.filter((p) => departmentObjectToUse.providerlist.includes(p));
            const reasonsByProviderPromiseArray = validProvidersToUse.map((provider) => {
                return getAppointmentReasons(locationId ? locationId : optionsObjToUse['/departments']['defaultLocation'], provider, firstTimePatient ? firstTimePatient : setFirstTimePatient );
            });

            // Reasons
            const reasons = await Promise.all(reasonsByProviderPromiseArray);
            const allReasons = reasons.reduce((acc,response) => {
                let reasonsToUse = response.data.patientappointmentreasons;
                let arrayToReturn = acc;

                if (reasonsToUse.length) {
                    arrayToReturn = arrayToReturn.concat(reasonsToUse);
                }

                return arrayToReturn;

            }, []);

            let uniquesReasons = [];
            allReasons.forEach((r) => {
                if (uniquesReasons.findIndex((reason) => reason.reasonid === r.reasonid) === -1) {
                    uniquesReasons.push(r);
                }
            });
            const uniquesReasonsIds = [...new Set(allReasons.map((reason) => reason.reasonid))];

            // Call to openAppointments
            const appointmentResponse = await getAppointments({
                bypassscheduletimechecks: optionsObjToUse['/appointments/open']['bypassscheduledtimechecks'],
                ignoreschedulablepermission: optionsObjToUse['/appointments/open']['ignoreschedulablepermission'],
                location: locationId ? locationId : optionsObjToUse['/departments']['defaultLocation'],
                reasons: uniquesReasonsIds,
                endDate: endDateToUse,
                startDate: startDateToUse,
                showfrozenslots: optionsObjToUse['/appointments/open']['showfrozenslots'],
                providersId: validProvidersToUse.length > 0 ? validProvidersToUse : []
            });

            let formattedAppointments = await appointmentResponse.data.appointments.sort(function(a,b) {
                if (a['date'] < b['date']) {
                    return -1;
                }
                if (a['date'] > b['date']) {
                    return 1;
                }

                if (a['providerid'] < b['providerid']) {
                    return -1;
                }
                if (a['providerid'] > b['providerid']) {
                    return 1;
                }

                if (a['starttime'] < b['starttime']) {
                    return -1;
                }
                if (a['starttime'] > b['starttime']) {
                    return 1;
                }
                return 0;
            });

            // Types
            const appointmentTypesResponse = await getAppointmentTypes();

            // Hubspot
            if (parsedHideHubspot !== 'true') {
                await this.getHubSpotContent(allProviders, validProvidersToUse);
            }

            this.setState({
                optionsObj: optionsObjToUse,
                appointments: formattedAppointments,
                departments: departmentsResponse.data.departments,
                providers: providersResponse.data.providers,
                reasons: uniquesReasons,
                setLocationId: locationId ? locationId : optionsObjToUse['/departments']['defaultLocation'],
                setProvidersId: providersId ? providersId : setProvidersId,
                setFirstTimePatient: firstTimePatient ? firstTimePatient : setFirstTimePatient,
                types: appointmentTypesResponse.data.appointmenttypes,
                isLoading: false,
                componentIsLoading: false,
                startDate: startDateToUse,
                endDate: endDateToUse,
                originalAppointments: formattedAppointments,
                appointmentsFilter: firstTimePatient === 'false' ? EXISTING_PATIENT_DEFAULT_REASON :  appointmentsFilter
            });

            // console.info('API Response Data: [0 appointments, 1 departments, 2 providers, 3 reasons, 4 types, 5 newPatientReasons]', [formattedAppointments, departmentsResponse.data.departments, providersResponse.data.providers, ['empty'], appointmentTypesResponse.data.appointmenttypes, newPatientReasons]);
        } catch (error) {
            this.setState({
                responseError: 'LOAD',
            });
        }
    };

    getHubSpotContent = async (allProviders, validProvidersToUse) => {
        try {
            let providersHubspotResponseArray = [];
            let hubspotProviderData = [];

            validProvidersToUse.forEach((provider) => {
                const providerObject = allProviders.find((p) => parseInt(p.providerid, 10) === parseInt(provider, 10));

                if (providerObject && providerObject.firstname && providerObject.lastname) {
                    providersHubspotResponseArray.push(getProviderDetails(providerObject.firstname, providerObject.lastname));
                }
            });

            if (providersHubspotResponseArray.length) {
                hubspotProviderData = await Promise.all(providersHubspotResponseArray);
            }

            if (hubspotProviderData.length) {
                hubspotProviderData.forEach((response) => {
                    response.data.objects.forEach((provider) => {
                        let firstName = provider.values[1] ? provider.values[1] : '';
                        let lastName = provider.values[11] ? provider.values[11] : '';

                        if (firstName !== '' && lastName !== '') {

                            let bio = provider.values[3] ? provider.values[3] : 'No bio available.';

                            let providerObjectToUpdate = allProviders.find((provider) => {
                                return (
                                    provider.firstname === firstName && provider.lastname === lastName
                                );
                            });

                            providerObjectToUpdate['hubspotBio'] = bio ? bio : '';

                            let providerImageObjet = provider.values[4];
                            if (providerImageObjet && providerImageObjet.url) {
                                let image = providerImageObjet.url ? providerImageObjet.url : '';

                                if(image !== '') {
                                    providerObjectToUpdate['hubspotImage'] = image ? image : '';
                                }
                            }
                        }
                    });
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('HubpSpot network error');
        }
    };

    handleSelectDepartment = (e) => {
        const {setProvidersId, startDate, endDate} = this.state;
        const {target} = e;
        const {value} = target;

        this.setState({
            setLocationId: value
        });
        this.getUpdatedDataWithConfig(null, value, setProvidersId, startDate, endDate);
    };

    handleSelectAppointment = (appointmentId) => {
        this.setState({
            selectedAppointmentId: appointmentId
        });
    };

    handleInputBlurUser = (event) => {
        const {target} = event;
        const {name} = target;
        const {userContactEntityDirty} = this.state;

        // Set field to dirty
        userContactEntityDirty[`${name}Dirty`] = true;

        // Check for field errors
        this.handleFieldErrors(name);

        this.setState({
            userContactEntityDirty,
        });
    };

    handleUserInputChange = (event) => {
        const {target} = event;
        const {name, value, checked} = target;
        const {userContactEntity, userContactEntityDirty} = this.state;

        // Set value in userContactEntity model
        if (name === 'consentForm' || name === 'guardCheck' || name === 'privacyForm') {
            userContactEntity[`${name}`] = checked;
            userContactEntityDirty[`${name}`] = true;
        } else {
            userContactEntity[`${name}`] = value;
        }

        this.toggleGuardian();

        // Check for field errors
        this.handleFieldErrors(name);

        this.setState({
            userContactEntity,
        }, this.handleValidForm);
    };

    handleDirtyForm = () => {
        const { userContactEntityDirty } = this.state;

        Object.keys(userContactEntityDirty).forEach((key) => userContactEntityDirty[`${key}`] = true);

        this.setState({
            userContactEntityDirty,
        });
    };

    toggleGuardian = () => {
        const {userContactEntity} = this.state;
        const yearDiff = moment().diff(userContactEntity.dob, 'years');

        userContactEntity['showGuardianCheck'] = userContactEntity.dob.length === 10 && yearDiff >= 0 && yearDiff < 18;

        this.setState({
            userContactEntity,
        });
    };

    handleFieldErrors = (name) => { // eslint-disable-line
        const {userContactEntityErrors, userContactEntity} = this.state;

        switch(name) {
            case 'zip':
            case 'email':
            case 'phoneNumber':
                userContactEntityErrors[`${name}`] = (CONSTANTS.REGEX[`${name}`].test(userContactEntity[`${name}`])) ? '' : `${name} is not valid`;
                break;
            case 'guardCheck':
            case 'dob':
                userContactEntityErrors.dob = userContactEntity.dob.length === 10 && moment(userContactEntity.dob).isValid()
                    ? '' : `${name} is not valid`;
                userContactEntityErrors.guardCheck = userContactEntity.showGuardianCheck && !userContactEntity.guardCheck
                    ? `${name} is not valid` : '';
                break;
            case 'firstName':
            case 'lastName':
            case 'city':
            case 'state':
            case 'sex':
            case 'address':
            case 'phoneNumberType':
                userContactEntityErrors[`${name}`] = userContactEntity[`${name}`] ? '' : `${name} is not valid`;
                break;
            default:
                break;
        }

        this.setState({
            userContactEntityErrors,
        });
    };

    handleUserSubmit = () => {
        const {userContactEntity} = this.state;

        // Check for form errors
        Object.keys(userContactEntity).forEach((name) => {
            this.handleFieldErrors(name);
        });

        // Dirty all form fields
        this.handleDirtyForm();

        // Check if form valid
        this.handleValidForm();

        // Submit form
        this.submitUserForm();
    };

    submitUserForm = () => {
        const {userContactFormValid} = this.state;

        // Submit form if valid
        if (userContactFormValid) {
            this.setState({
                isLoading: false,
                responseError: '',
                step: '',
                validUserId: true
            });
        }
    };

    handleClosePage = () => {
      const {queryStringValues, closeCallback} = this.props;
      const {parsedMobileApp} = queryStringValues;
      if(parsedMobileApp && closeCallback) {
          const closeObject = {
              '_body': {
                  'step': 'CLOSE'
              }
          };
          closeCallback(closeObject);
      }
    };

    handleStepChange = (step) => {
      const {queryStringValues, stepChangeCallback} = this.props;
      const {parsedMobileApp} = queryStringValues;
      if(parsedMobileApp && stepChangeCallback && step) {
          const stepChangeObject = {
              '_body': {
                  'step': step
              }
          };
          stepChangeCallback(stepChangeObject);
      }
    };

    handleBookAppointment = async () => {
        const {queryStringValues, successfulCallback} = this.props;
        const {parsedMobileApp} = queryStringValues;

        try {
            this.setState({
                isLoading: true
            });

            const {appointments, appointmentInsurance, appointmentNotes, userContactEntity, selectedAppointmentId, setLocationId} = this.state;
            const selectedAppointmentObject = appointments.find((appt) => appt.appointmentid === selectedAppointmentId);
            const {date, starttime, appointmenttypeid, departmentid, providerid} = selectedAppointmentObject;
            const bookAppoitmentWithPatient = await setPatientAppointment(selectedAppointmentObject, userContactEntity, setLocationId, appointmentInsurance, appointmentNotes);

            if (bookAppoitmentWithPatient.status === 200) {
                const {data} = bookAppoitmentWithPatient;
                const validBookedAppt = data[0];

                this.setState({
                    isLoading: false,
                    responseError: '',
                    validBooking: true,
                    validBookedAppt
                });

                if (parsedMobileApp && successfulCallback) {
                    const sucessfulBookingObj = {
                        '_body': {
                            'state': 'SUCCESS',
                            'dateTime': date,
                            'startTime': starttime,
                            'appointmentType': appointmenttypeid,
                            'appointmentReason': 'Medicine Refill',
                            'appointmentLocation': departmentid,
                            'appointmentProvider': providerid
                        }
                    };

                    successfulCallback(sucessfulBookingObj);
                }
            }
        } catch (error) {
            this.setState({
                responseError: 'BOOK',
                isLoading: false
            });
        }
    };

    handleDismissAppointment = () => {
        this.setState({
            selectedAppointmentId: null,
            step: ''
        });
    };

    handleAppointmentDataUpdates = (e) => {
        const {target} = e;
        const {value, name} = target;

        const updatedStateObject = {};
        updatedStateObject[`${name}`] = value;

        this.setState(updatedStateObject);
    };

    handleEditSection = () => {
        this.setState({
            step: 'user'
        });
    };

    handleFilterReason = (event) => {
        const {originalAppointments} = this.state;

        const {target} = event;
        const {value} = target;
        const filterAppointmentsViaReason = (appointment) => {
            const {reasonid} = appointment;
            let selectForUse = false;

            reasonid.forEach((reason) => {
                if (reason === value){
                    selectForUse = true;
                }
            });

            return selectForUse === true ? appointment : null;
        };

        let appointmentsToUse = [...originalAppointments];
        let appointmentsWithFilter = [];

        if (value) {
            appointmentsWithFilter = appointmentsToUse.filter(filterAppointmentsViaReason);
        } else {
            appointmentsWithFilter = originalAppointments;
        }

        this.setState({
            appointments: appointmentsWithFilter,
            appointmentsFilter: value
        });
    };

    handleToggleDateColumn = (provider, date) => {
        const {expandedDates} = this.state;
        const newExpandedDates = Object.assign({}, expandedDates);

        if (expandedDates[`${provider}`]) {
            delete newExpandedDates[`${provider}`];
        } else {
            newExpandedDates[`${provider}`] = true;
        }

        this.setState({
            expandedDates: newExpandedDates
        });
    };

    handleChangeStartDate = (date) => {
        const {isMobile, setLocationId, setProvidersId} = this.state;

        if (date !== null) {
            let startDateToUse = moment(date).startOf('day').format('MM/DD/YYYY');
            let endDateToUse = isMobile ? moment(startDateToUse).endOf('day').format('MM/DD/YYYY') : moment(startDateToUse).startOf('day').add(DESKTOPDAYSAHEAD, 'days').format('MM/DD/YYYY');
            this.setState({
                endDate: endDateToUse,
                startDate : startDateToUse
            });
            this.getUpdatedDataWithConfig(null, setLocationId, setProvidersId, startDateToUse, endDateToUse);
        }
    };

    handleDateChange = (action, days) => {
        const {isMobile, startDate, setLocationId, setProvidersId} = this.state;
        let startDateToUse;
        let endDateToUse;

        if (action === 'subtract') {
            startDateToUse = moment(startDate).startOf('day').subtract(parseInt(days, 10), 'days').format('MM/DD/YYYY');

            if (moment(startDateToUse).isBefore(moment())) {
                startDateToUse = moment().startOf('day').format('MM/DD/YYYY');
            }
        }

        if (action === 'add') {
            startDateToUse = moment(startDate).startOf('day').add(parseInt(days,10), 'days').format('MM/DD/YYYY');
        }

        endDateToUse = isMobile ? moment(startDateToUse).endOf('day').format('MM/DD/YYYY') : moment(startDateToUse).startOf('day').add(DESKTOPDAYSAHEAD, 'days').format('MM/DD/YYYY');

        this.getUpdatedDataWithConfig(null, setLocationId, setProvidersId, startDateToUse, endDateToUse);
    };

    handleChangeUserType = (event) => {
        const {setLocationId, setProvidersId, startDate, endDate, setFirstTimePatient} = this.state;
        const {target} = event;
        const {value} = target;

        this.setState({
            setFirstTimePatient: value
        });
        this.getUpdatedDataWithConfig(null, setLocationId, setProvidersId, startDate, endDate, value ? value : setFirstTimePatient);
    };

    handleValidForm = () => {
        const { userContactEntityErrors, userContactEntity } = this.state;
        const { firstName, lastName, phoneNumber, dob, address, city, state, zip, sex } = userContactEntity;

        const formErrors = Object.values(userContactEntityErrors).join('') !== '';
        const formEmptyValues = [firstName, lastName, phoneNumber, dob, address, city, state, zip, sex].filter((item) => item === '').length;

        this.setState({
            userContactFormValid: !formErrors && !formEmptyValues,
        });
    };

    handlePageError = () => {
        const {responseError} = this.state;

        switch(responseError) {
            case 'LOAD':
                return window.location.reload();
            case 'BOOK':
                return this.handleBookAppointment();
            default:
                return () => {};
        }
    };

    render() {
        const {appointments, appointmentsFilter, appointmentNotes, appointmentInsurance, dateSpan, departments, expandedDates, isLoading, componentIsLoading,
            isMobile, userContactEntity, userContactEntityDirty, reasons, responseError, providers, selectedAppointmentId, step, userContactFormValid,
            setLocationId, validUserId, validBooking, validBookedAppt, startDate, endDate, setFirstTimePatient, userContactEntityErrors, showDatePicker
        } = this.state;

        const selectedAppointmentObject = appointments.find((appt) => appt.appointmentid === selectedAppointmentId);
        const {queryStringValues} = this.props;

        let stepToShow = '';
        let progress = 25;

        if (step === '') {
            stepToShow = 'appointment';
        }

        if (selectedAppointmentId != null || step === 'user') {
            stepToShow = 'user';
            progress = 50;
        }

        if (selectedAppointmentId && validUserId && (step !== 'user')) {
            stepToShow = 'confirm';
            progress = 75;
        }

        if ((selectedAppointmentId && validUserId && validBooking) || step === 'final') {
            stepToShow = 'final';
            progress = 100;
        }
        this.handleStepChange(stepToShow);

        return (
            <div className="vmd-book-appointment-component">
                <div>
                    <div className="container">
                        {
                            (!isLoading && !responseError) && <progress className={`progress is-small ${validBooking === true ? 'is-success' : ''}`} value={progress} max="100">{progress}%</progress>
                        }
                        {
                            responseError &&
                                <div className="has-text-centered">
                                    <img src={bandaidIcon} className="icon-bandaid" alt="bandaid icon"/>
                                    <h2 className="is-size-4">Something went wrong</h2>
                                    <p className="error-description color-primary">{ERROR_CODES[`${responseError}`]}</p>
                                    <button
                                        className="button is-primary-button button-large is-size-5"
                                        onClick={() => {this.handlePageError();}}>
                                        <img src={refreshIcon} alt="refresh page icon" className="icon-refresh"/>
                                            Refresh Page
                                    </button>
                                </div>
                        }
                        {
                            (isLoading && !responseError) && <BookAppointmentLoadingStatus />
                        }
                        {
                            (stepToShow === 'appointment' && !isLoading && !responseError) &&
                                <BookAppointmentList
                                    appointments={appointments}
                                    appointmentsFilter={appointmentsFilter}
                                    dateSpan={dateSpan}
                                    departments={departments}
                                    expandedDates={expandedDates}
                                    handleFilterReason={this.handleFilterReason}
                                    handleSelectAppointment={this.handleSelectAppointment}
                                    handleSelectDepartment={this.handleSelectDepartment}
                                    handleExpansionDateColumn={this.handleToggleDateColumn}
                                    handleUpdateDate={this.handleDateChange}
                                    handleUpdateStartDate={this.handleChangeStartDate}
                                    handleUserTypeChange={this.handleChangeUserType}
                                    handleDatePicker={this.handleDatePicker}
                                    isMobile={isMobile}
                                    componentIsLoading={componentIsLoading}
                                    providers={providers}
                                    reasons={reasons}
                                    startDate={startDate}
                                    setFirstTimePatient={setFirstTimePatient}
                                    endDate={endDate}
                                    showDatePicker={showDatePicker}
                                    setLocationId={setLocationId} />
                        }
                        {
                            (stepToShow === 'user' && !isLoading && !responseError) &&
                                <BookAppointmentUserDetails
                                    appointmentsFilter={appointmentsFilter}
                                    departments={departments}
                                    userContactEntity={userContactEntity}
                                    userContactEntityDirty={userContactEntityDirty}
                                    userContactEntityErrors={userContactEntityErrors}
                                    handleInputChange={this.handleUserInputChange}
                                    handleInputBlurUser={this.handleInputBlurUser}
                                    handleUserSubmit={this.handleUserSubmit}
                                    handleDismissAppointment={this.handleDismissAppointment}
                                    title={'Patient information'}
                                    reasons={reasons}
                                    selectedAppointmentId={selectedAppointmentObject}
                                    setLocationId={setLocationId}
                                    providers={providers}
                                    userContactformValid={userContactFormValid}/>
                        }
                        {
                            (stepToShow === 'confirm' && validUserId && !isLoading && !validBooking && !responseError) &&
                                <BookAppointmentConfirm
                                    appointment={selectedAppointmentObject}
                                    appointmentsFilter={appointmentsFilter}
                                    appointmentNotes={appointmentNotes}
                                    appointmentInsurance={appointmentInsurance}
                                    departments={departments}
                                    handleAppointmentDataUpdates={this.handleAppointmentDataUpdates}
                                    handleBookAppointment={this.handleBookAppointment}
                                    handleDismissAppointment={this.handleDismissAppointment}
                                    handleEditSection={this.handleEditSection}
                                    handleUserInputChange={this.handleUserInputChange}
                                    reasons={reasons}
                                    selectedAppointmentId={selectedAppointmentId}
                                    setLocationId={setLocationId}
                                    title={'Confirm Appointment'}
                                    providers={providers}
                                    user={userContactEntity} />
                        }
                        {
                            (stepToShow === 'final' && validBooking && !isLoading && !responseError) &&
                                <BookAppointmentSuccess
                                    appointment={validBookedAppt}
                                    appointmentsFilter={appointmentsFilter}
                                    appointmentNotes={appointmentNotes}
                                    appointmentInsurance={appointmentInsurance}
                                    departments={departments}
                                    providers={providers}
                                    reasons={reasons}
                                    user={userContactEntity}
                                    setLocationId={setLocationId}
                                    title={'Appointment successfully booked'}
                                    handleClosePage={this.handleClosePage}
                                    queryStringValues={queryStringValues} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default BookAppointmentComponent;
