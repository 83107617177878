import React from 'react';
import ReactHtmlParser from 'react-html-parser'

import BookAppointmentLink from '../BookAppointmentLink/book_appointment_link';

import './book_appointment_list_section.scss';

const SLOTS_TO_SHOW = 4;

const BookAppointmentListSection = (props) => {
    const {data = {}, dates = [], departmentObject = {}, expandedDates, handleSelectAppointment, handleExpansionDateColumn, provider = {}, isMobile} = props;
    let columnElement = [];

    dates.forEach((date, dateIndex) => { 
            let rowElement = [];
            const appointmentCollection = data[`${date}`] || [];
            let showMoreButton;

            if (appointmentCollection.length) {
                appointmentCollection.forEach((appt, apptIndex) => {
                    const {appointmentid} = appt;

                    if (!isMobile) {
                        showMoreButton = (
                            <button
                                className="button is-secondary-button"
                                onClick={() => {
                                    handleExpansionDateColumn(provider.providerid, date);
                                }}>more</button>
                        );
                    }

                    rowElement[`${apptIndex}`] = (
                        <React.Fragment
                            key={`appointment-item-${apptIndex}`}>
                            <BookAppointmentLink
                                key={`appointment-item-link-${apptIndex}`}
                                department={departmentObject}
                                classNames={(!isMobile && apptIndex >= SLOTS_TO_SHOW && (!expandedDates[`${provider.providerid}`])) ? 'after-threshold' : ''}
                                onBookAppointment={() => {
                                    handleSelectAppointment(appointmentid);
                                }}
                                {...appt} />
                            {
                                (!isMobile && apptIndex === SLOTS_TO_SHOW && !expandedDates[`${provider.providerid}`]) && showMoreButton
                            }
                        </React.Fragment>
                    );
                });
            }

            let emptyDayElement = (
                <div className="no-availability">
                    <div className='appointment-button-vertical-wrapper'>
                        <span className='empty-slot-placeholder'>---</span>
                        <span className='empty-slot-placeholder'>---</span>
                        <span className='empty-slot-placeholder'>---</span>
                        <span className='empty-slot-placeholder'>---</span>
                        <span className='empty-slot-placeholder'>---</span>
                    </div>
                </div>
            );
        
            columnElement[`${dateIndex}`] = (
                <div className="column column-date is-marginless" key={`appointment-section-item-${dateIndex}`}>
                    {
                        <div className={`${isMobile ? 'appointment-button-horizontal-wrapper' : 'appointment-button-vertical-wrapper'}`}>
                            {(rowElement && rowElement.length > 0) ? rowElement : emptyDayElement}
                        </div>
                    }
                </div>
            );
    });

    const columnWrapper = (
        <div className="columns">
            {
                !isMobile && <div className="column is-marginless is-paddingless is-1"/>
            }
            {
                columnElement
            }
            {
                !isMobile && <div className="column is-marginless is-paddingless is-1"/>
            }
        </div>
    );

    const providerElement = (
        <div className="provider-information is-clearfix">
            {
                (provider.lastname && provider.firstname) &&
                <img
                    src={provider.hasOwnProperty('hubspotImage') ? provider.hubspotImage : 'https://cdn2.hubspot.net/hubfs/6509886/default-profile.png?width=100'}
                    width="45px"
                    alt={`Village Medical - ${provider.lastname}, ${provider.firstname} ${provider.providertype ? provider.providertype : ''}`}
                    data-test="provider-photo"/>
            }
            {
                (provider && provider.firstname && provider.lastname) ?
                    <>
                        <p className="provider-name">{provider.lastname + ', ' + provider.firstname + ' ' + (provider.providertype ? provider.providertype : '')}</p>
                    </> : 'No provider details'
            }
            {
                (departmentObject.patientdepartmentname &&
                    <span className="department-name">
                        {departmentObject.patientdepartmentname}
                    </span>
                )
            }
            {
                (provider && provider.hubspotBio && !isMobile &&
                    <div className="provider-bio">
                        {ReactHtmlParser(provider.hubspotBio)}
                    </div>
                )
            }
        </div>
    );

    const appointmentsElement = (
        <div className="appointment-information is-clearfix">
            {columnWrapper && columnWrapper}
        </div>
    );

    return (
        <div className="vmd-book-appointment-list-section">
            {
                isMobile ?
                    <>
                        {providerElement}
                        {appointmentsElement}
                    </>
                :
                    <div className="columns">
                        <div className="column">{providerElement}</div>
                        <div className="column">{appointmentsElement}</div>
                    </div>
            }
        </div>
    );    
};

export default BookAppointmentListSection;
