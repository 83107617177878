import React from 'react';
import moment from 'moment-timezone';

import './book_appointment_H_time_control.scss';

const timezoneNameFull = moment.tz.guess().replace(/_/, ' ');
const timezoneNameShort = moment.tz(moment.tz.guess()).format('z');

const BookAppointmentHTimeControl = (props) => {
    const {dateSpan, handleUpdateDate, isMobile, startDate} = props;

    let leftButtonControl = (
        <button
            className="button shift-days shift-days-less"
            data-test="appointment-list-time-previous"
            disabled={!startDate || moment(startDate, 'MM/DD/YYYY').isBefore(moment())}
            onClick={(e) => {
                handleUpdateDate('subtract', dateSpan);
            }}>
                {'<'}
        </button>
    );

    let rightButtonControl = (
        <button
            className="button shift-days shift-days-plus"
            data-test="appointment-list-time-next"
            onClick={(e) => {
                handleUpdateDate('add', dateSpan);
            }}>
                {'>'}
        </button>
    );

    let numberOfDates = [];
    let counter = 0;

    if (isMobile) {
        let previousDay = (
            <div
                className="column center-content is-marginless is-paddingless"
                key="appointment-list-item-date-month-wrapper-pre" >
                <button
                    className="button is-date"
                    data-test="appointment-list-time-date"
                    disabled={moment(startDate, 'MM/DD/YYYY').isBefore(moment())}
                    onClick={() => {
                        handleUpdateDate('subtract', 1);
                    }}>
                        {
                            <>
                                <span className="appointment-list-time-date-month">
                                    {
                                        moment(startDate, 'MM/DD/YYYY').startOf('day').subtract(1, 'days').format('MMM')
                                    }
                                </span>
                                <span className="appointment-list-time-date-day">
                                    {
                                        moment(startDate, 'MM/DD/YYYY').startOf('day').subtract(1, 'days').format('D')
                                    }
                                </span>
                            </>
                        }
                </button>
            </div>
        );
        numberOfDates.push(previousDay);
    }

    const createDateSpecificFunction = (counterValue) => () => handleUpdateDate('add', counterValue);

    while(counter < dateSpan) {
        let month = counter === 0 ? moment(startDate, 'MM/DD/YYYY').startOf('day').format('MMM'): moment(startDate, 'MM/DD/YYYY').startOf('day').add(counter, 'days').format('MMM');
        let day = counter === 0 ? moment(startDate, 'MM/DD/YYYY').startOf('day').format('D') : moment(startDate, 'MM/DD/YYYY').startOf('day').add(counter, 'days').format('D');
        let dayElement = (
            <div
                className="column center-content is-marginless is-paddingless"
                key={`appointment-list-item-date-month-wrapper-${counter}`}>
                <button
                    className={`button is-date ${(isMobile && counter === 0) ? 'active' : ''}`}
                    data-test="appointment-list-time-date"
                    // eslint-disable-next-line no-loop-func
                    onClick={createDateSpecificFunction(counter)}>
                        {
                            <>
                                <span className={`appointment-list-time-date-month ${(isMobile && counter === 0) ? 'active' : ''}`}>{month}</span>
                                <span className={`appointment-list-time-date-day ${(isMobile && counter === 0) ? 'active' : ''}`}>{day}</span>
                            </>
                        }
                </button>
            </div>
        );
        numberOfDates.push(dayElement);
        counter += 1;
    }

    let controlElement = (
        <div className="columns is-mobile">
            <div className="column is-marginless is-paddingless has-valigned-items is-1">
                {
                    moment(startDate, 'MM/DD/YYYY').isAfter(moment()) && leftButtonControl
                }
            </div>
            {
                numberOfDates
            }
            <div className="column is-marginless is-paddingless has-valigned-items is-1">
                {
                    rightButtonControl   
                }
            </div>
        </div>
    );

    return (
        <>
            <div
                className={`vmd-book-appointment-h-time-control date-section is-sticky-section ${isMobile ? 'mobile-version' : ''}`}
                data-test="">
                {
                    isMobile ?
                        controlElement
                        :
                        <div className="columns columns is-vcentered">
                            <div className="column">
                                <span className="section-header">Select a time:</span>
                                <p data-test="timezone-desktop">Times shown in {timezoneNameShort} ({timezoneNameFull})</p>
                            </div>
                            <div className="column">{controlElement}</div>
                        </div>
                }
            </div>
            {
                isMobile && <p className="timezone-title" data-test="timezone-mobile">Times shown in {timezoneNameShort} ({timezoneNameFull})</p>
            }
        </>
    );
};

export default BookAppointmentHTimeControl;
