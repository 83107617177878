import React from 'react';
import './maintenance.scss'
import bandaidIcon from '../component-assets/svg/bandaid.svg';

const MaintenancePage = (props) => {
        const { MaintenanceMessage } = props;

        return (
            <main className="maintenance-content" >
                <section className="app-section">
                    <div className="has-text-centered">
                        <img src={bandaidIcon} className="icon-bandaid" alt="bandaid icon"/>
                        <h2 className="error-description">We'll be right back!</h2>
                        <h2 className="subtitle">We're performing scheduled maintenance, but we're expected to be back shortly</h2>
                        <h2 className="subtitle">{ MaintenanceMessage }</h2>
                        <h2 className="subtitle">Please, check back soon!</h2>
                     </div>
                </section>
            </main>
        );
}

export default MaintenancePage;
