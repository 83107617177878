import React from 'react';
import './home.scss';

const ConfirmationPageSection = (props) => {
    const {paymentVerification} = props;
    let title = 'Payment Not Verified';
    let iconClassName = 'fa fa-exclamation-circle status-icon color-failure';

    if (paymentVerification === 'true') {
        title = 'Payment Successful';
        iconClassName = 'fa fa-check-circle status-icon color-success verification-subtitle';
    }

    return (
        <div className="confirmation-page-section">
            <main className="app-content">
                <section className="hero is-small verification-content">
                    <div className="hero-body" id="steps">
                        <div className="container">
                            <div className="columns is-centered">
                                <div className="column is-three-fifths">
                                    <div className="has-text-centered">
                                        <i className={iconClassName}/>
                                        <h1>{title}</h1>
                                        <p className="subtitle verification-content">Check your email for payment details</p>
                                    </div>
                                </div>

                            </div>
                            <div className="columns is-centered">
                                <div className="column is-three-fifths color-white-bluish-bg">
                                    <div className="message-box-margin">
                                        <h2><i className="fas fa-video"/> Video Visit Instructions</h2>
                                        <p className="message-margin">
                                            You'll receive a text or email link shortly before your
                                            scheduled appointment. One click opens the "virtual waiting room",
                                            where your provider will join you shortly to start your visit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default ConfirmationPageSection;
