// API CONSTANTS
// 
// These variables are used for constructing the endpoints for the application. To be used for both a local environment and a hosted production bundle
// Local environment should use and hit constructed athena API urls while a production bundle should hit our ace-inbound constructed urls

const APP_ENV = process.env.REACT_APP_ENV || 'development';

const IS_PRODUCTION = APP_ENV === 'production';

let BASE_URL = 'https://ace-inbound-public-uat.villagemedical.com';
if (IS_PRODUCTION || APP_ENV === 'uat') {
    BASE_URL = 'https://ace-inbound-public-prod.villagemedical.com';
}

const API_URL = IS_PRODUCTION ? `${BASE_URL}/vmd-tx-iah/villagefamilypractice/athena` : `${BASE_URL}/vmd-tx-iah/villagefamilypractice/athena`;
// const BASE_URL = IS_PRODUCTION ? 'https://ace-inbound-public-prod.villagemedical.com' : 'https://api.athenahealth.com/preview1/13711';
// const API_URL = IS_PRODUCTION ? `${BASE_URL}/vmd-tx-iah/villagefamilypractice/athena` : 'https://api.athenahealth.com/preview1/13711';

const API_HUBSPOT_URL = 'https://api.hubapi.com/hubdb/api/v2/tables/2029266/rows?portalId=6509886';
const API_APPOINTMENTS_OPEN_URL = `${API_URL}/appointments/open`;
const API_DEPARTMENTS_URL =  `${API_URL}/departments`;
const API_PROVIDERS_URL = `${API_URL}/providers`;
const API_REASONS_URL = `${API_URL}/patientappointmentreasons`;
const API_REASONS_NEW_URL = `${API_URL}/patientappointmentreasons/newpatient`;
const API_REASONS_EXISTING_URL = `${API_URL}/patientappointmentreasons/existingpatient`;
const API_TYPES_URL = `${API_URL}/appointmenttypes`;
const API_SET_APPOINTMENT_BOOK_URL = `${API_URL}/appointments`;
const API_SET_PATIENT_URL = `${API_URL}/patients`;
const API_SET_PATIENT_APPOINTMENT_URL = `${API_URL}/patient-appointment`;

// DEFAULT CONSTANTS
//
// These constants are used for defaults
// TODO: These should be moved from the JS code and be returned by the first call to the ace server endpoint. For now, they will be collected here until moved.

const NEW_PATIENT_DEFAULT_REASON = 2;
const EXISTING_PATIENT_DEFAULT_REASON = 4;

// production: sku_GzqD7PuYLbK2Wy
// test: sku_H04CfkFtWSdvI1
let TELEHEALTH_SKU = 'sku_GzqD7PuYLbK2Wy';

// test: pk_test_Ou4SztXNcpyik29jJ2dyjzBg00b57bzsWB
let STRIPE_KEY = 'CHANGE_TO_PRODUCTION';
let STRIPE_SUCCESS_URL = 'https://joouuslgs6.execute-api.us-east-1.amazonaws.com/Prod/api/v1/success';
let STRIPE_CANCEL_URL = 'https://joouuslgs6.execute-api.us-east-1.amazonaws.com/Prod/api/v1/canceled';

if (!IS_PRODUCTION) {
    TELEHEALTH_SKU = 'sku_H04CfkFtWSdvI1';
    STRIPE_KEY = 'pk_test_Ou4SztXNcpyik29jJ2dyjzBg00b57bzsWB';
}else {
    TELEHEALTH_SKU = 'sku_GzqD7PuYLbK2Wy';
    STRIPE_KEY = 'pk_live_UGT9DKhANSMN2F6Czf4UR2ua00rPvUH0VU';
}

let DEPARTMENT_ID = '121';// receive from config
if (!IS_PRODUCTION && APP_ENV !== 'uat') {
    DEPARTMENT_ID = '1';
}

const APPOINTMENT_PRICE = '$59.00';

// AUTHENTICATION CONSTANTS
// These variables are used to construct auth headers for the athena api endpoints

const TOKEN = '';
const testHeaders = {
    headers: {
        'Authorization': 'bearer ' + TOKEN,
    }
};
const productionHeaders = {
    headers: {}
};
const AUTH_HEADERS = IS_PRODUCTION ? productionHeaders : testHeaders;

// MARKET CONSTANTS
//
// Used to prettify athena values to match what we display on the hubspot site

const STATE_TO_MARKET_LOOKUP = {
    'TX': 'vmd-tx-iah',
    'AZ': 'vmd-az-phx'
};
const MARKET_TO_DISPLAYNAME_LOOKUP = {
    'vmd-az-phx' : 'Phoenix',
    'vmd-tx-iah' : 'Houston'
};

// REGEX VALIDATIONS
const REGEX = {
    email: RegExp(/[^@]+@[^.]+\..+/),
    phoneNumber: RegExp(/^\d+$/),
    zip: RegExp(/^\d{5}$|^\d{5}-\d{4}$/),
};

// BASIC TEST DATA
// Use for testing the loading of the checkout component on the main app.js page - will need to remove once we have a more robust tests. 
const testObjForCheckoutPreview = {
    testDepartmentId: 121,
    testDepartments : [
        {
            address: '9055 Katy Freeway',
            address2: 'Suite 200',
            chartsharinggroupid: '1',
            city: 'HOUSTON',
            clinicals: 'ON',
            communicatorbrandid: '1',
            departmentid: '121',
            doesnotobservedst: 'false',
            ishospitaldepartment: 'false',
            latitude: '29.7835286',
            longitude: '-95.5122225',
            medicationhistoryconsent: 'true',
            name: 'VM_HOU_Virtual Care',
            patientdepartmentname: 'Village Medical - Virtual Care',
            placeofservicefacility: 'false',
            placeofservicetypeid: '02',
            placeofservicetypename: 'TELEHEALTH',
            providergroupid: '1',
            providergroupname: 'Village Medical - Houston',
            providerlist: ['218', '270', '228', '203', '285', '299', '208', '211', '193', '220', '222', '209'],
            state: 'TX',
            timezone: -5,
            timezonename: 'America/Chicago',
            timezoneoffset: -6,
            zip: '77024-1629',
        }
    ],
    testAppointmentData : {
        appointmentid: '4632593',
        appointmenttype: 'Video 15',
        appointmenttypeid: '591',
        date: '04/03/2020',
        departmentid: '121',
        duration: '15',
        localproviderid: '193',
        patientappointmenttypename: 'Video Visit',
        providerid: '193',
        reasonid: ['2'],
        starttime: '09:00'
    },
    testProviders : [
        {
          ansinamecode: 'Physician Assistants & Advanced Practice Nursing Providers : Nurse Practitioner : Family (363LF0000X)',
          ansispecialtycode: 'test55test',
          billable: 'true',
          createencounteroncheckin: 'true',
          displayname: 'Nurse, Test NP',
          entitytype: 'Person',
          firstname: 'Test',
          hideinportal: 'false',
          homedepartment: 'VM_HOU_Mason Creek',
          lastname: 'Nurse',
          npi: '555',
          providerid: '193',
          providertype: 'NURSE PRACTITIONER, SUPERVISING(NP,S)',
          providertypeid: 'NP, S',
          providerusername: 'tnurse244',
          schedulingname: 'Nurse_t',
          sex: 'F',
          specialty: 'Family Medicine',
          supervisingproviderid: '193',
          supervisingproviderusername: 'Nurse_t'
        },
        {
          ansinamecode: 'Physician Assistants & Advanced Practice Nursing Providers : Nurse Practitioner (363L00000X)',
          ansispecialtycode: 'test666test',
          billable: 'true',
          createencounteroncheckin: 'true',
          displayname: 'Test, Doctor NP',
          entitytype: 'Person',
          firstname: 'Test',
          hideinportal: 'false',
          homedepartment: 'VM_HOU_Mason Creek',
          lastname: 'Doctor',
          npi: '456',
          providerid: '220',
          providertype: 'NURSE PRACTITIONER, SUPERVISING(NP,S)',
          providertypeid: 'NP, S',
          providerusername: 'tdoctor',
          schedulingname: 'Doctor_T',
          sex: 'F',
          specialty: 'Family Medicine',
          supervisingproviderid: '220',
          supervisingproviderusername: 'Doctor_T',
        }
    ],
    testReasons : [
        {
          description: 'I am a new patient and I would like to schedule my first visit.  Weekday appointments after 5:15pm and Saturday appointments are considered after hours/urgent care and have an accompanying $25 fee that is billed to your insurance.',
          reason: 'New Patient',
          reasonid: '2',
          reasontype: 'new',
          schedulingmaxdays: '90',
          schedulingminhours: '24'
        }
    ],
    testUser : {
        address:'125 s clark',
        address2: 'suite 9000',
        consentForm: '',
        city: 'Chicago',
        dob: '01/01/1891',
        email: 'nbghost@gmail.com',
        firstName: 'nbauers',
        lastName: 'ghost',
        phoneNumber: '312-333-3333',
        phoneNumberType: 'Mobile',
        guardCheck: false,
        showGuardianCheck: false,
        state: 'Il',
        zip: '60606'
    }
};

const TESTOBJECT = testObjForCheckoutPreview; //Object.assign({}, IS_PRODUCTION === false ? testObj : productionObj);

const STATE_CODES = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

const ERROR_CODES = {
    BOOK: 'There was a problem booking the appointment. Please refresh the page and try again.',
    LOAD: 'There was a problem loading appointments. Please refresh the page and try again.',
};

export default {
    AUTH_HEADERS,
    API_APPOINTMENTS_OPEN_URL,
    API_HUBSPOT_URL,
    API_DEPARTMENTS_URL,
    API_PROVIDERS_URL,
    API_REASONS_URL,
    API_REASONS_NEW_URL,
    API_REASONS_EXISTING_URL,
    API_TYPES_URL,
    API_SET_APPOINTMENT_BOOK_URL,
    API_SET_PATIENT_URL,
    API_SET_PATIENT_APPOINTMENT_URL,
    APP_ENV,
    APPOINTMENT_PRICE,
    BASE_URL,
    DEPARTMENT_ID,
    ERROR_CODES,
    EXISTING_PATIENT_DEFAULT_REASON,
    MARKET_TO_DISPLAYNAME_LOOKUP,
    NEW_PATIENT_DEFAULT_REASON,
    STATE_CODES,
    STATE_TO_MARKET_LOOKUP,
    TOKEN,
    TELEHEALTH_SKU,
    STRIPE_KEY,
    STRIPE_SUCCESS_URL,
    STRIPE_CANCEL_URL,
    REGEX,
    TESTOBJECT
};
