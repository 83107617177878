import React from 'react';
import {loadStripe} from '@stripe/stripe-js';

import CONSTANTS from '../CONSTANTS.js';
const {TELEHEALTH_SKU, STRIPE_KEY, STRIPE_SUCCESS_URL, STRIPE_CANCEL_URL} = CONSTANTS;

export default class PaymentForm extends React.Component {
    constructor(props){
        super(props);
        this.stripe = null;

        this.state = {
            sku: TELEHEALTH_SKU,
            warning: ''
        };

        this.onCheckout = this.onCheckout.bind(this);
    }

    componentDidMount(){
        this.initializeComponent();
    }

    async initializeComponent() {
        this.stripe = await loadStripe(STRIPE_KEY);
    }

    formatQueryString(user) {
        let queryString = '?';
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(user)) {
            queryString = `${queryString}${key}=${value}&`;
        }
        return queryString;
    }

    onCheckout() {
        let userQueryString = this.formatQueryString(this.props.user);
        this.stripe.redirectToCheckout({
            items: [{sku: this.state.sku, quantity: 1}],
            customerEmail: this.props.user.email,
            // TODO: Use one of the strategies described in: https://stripe.com/docs/payments/checkout/fulfillment
            successUrl: `${STRIPE_SUCCESS_URL}${userQueryString}`,
            cancelUrl: `${STRIPE_CANCEL_URL}${userQueryString}`,
        })
        .then((result) => {
            if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network error, display the localized error message to the customer
                const displayError = document.getElementById('error-message');
                displayError.textContent = result.error.message;
            }
        });
    }

    render() {
        return (
            <div className="column is-2 has-text-right">
                <button
                    onClick={this.onCheckout}
                    className="button is-large"
                    id="checkout-button-sku_GzqD7PuYLbK2Wy"
                    role="link">Pay Now</button>
                <div id="error-message"/>
            </div>
        );
    }
}

PaymentForm.defaultProps = {
  email: ''
};