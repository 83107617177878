import React from 'react';

import BookAppointmentSelectedAppointment from '../BookAppointmentSelectedAppointment/book_appointment_selected_appointment';
import './book_appointment_user_details.scss';
import CONSTANTS from '../../CONSTANTS';

const {STATE_CODES} = CONSTANTS;

const BookAppointmentUserDetails = (props) => {
    const {appointmentsFilter, departments, handleInputChange, handleUserSubmit, userContactEntity, userContactEntityDirty, userContactEntityErrors, handleDismissAppointment, handleInputBlurUser, title, reasons, selectedAppointmentId, setLocationId, providers} = props;
    const {address, address2, city, dob, email, firstName, lastName, phoneNumber, phoneNumberType, guardCheck, showGuardianCheck, state, zip, sex} = userContactEntity;
    const {addressDirty, cityDirty, dobDirty, emailDirty, firstNameDirty, lastNameDirty, phoneNumberDirty, phoneNumberTypeDirty, guardCheckDirty, stateDirty, zipDirty, sexDirty} = userContactEntityDirty;

    const guardianQuestion = (
        <div className="field is-horizontal">
            <div className="field-body">
                <div className="field checkbox-control">
                    <label className="checkbox">
                        <input
                            className={`checkbox ${guardCheckDirty && !guardCheck ? 'is-danger' : ''}`}
                            data-test="guardian-check"
                            onChange={(e) => {handleInputChange(e);}}
                            onBlur={(e) => {handleInputBlurUser(e);}}
                            name="guardCheck"
                            checked={guardCheck}
                            type="checkbox" />
                        <span className="input-text">Patients under 18 must have a parent or guardian schedule their appointments:*<br />
                        I certify that I am the parent or legal guardian of this individual.</span>
                    </label>
                </div>
            </div>
        </div>  
    );

    return (
        <div className="vmd-book-appointment-user-details">
            {
                <BookAppointmentSelectedAppointment
                    appointmentsFilter={appointmentsFilter}
                    departments={departments}
                    handleDismissAppointment={handleDismissAppointment}
                    reasons={reasons}
                    appointment={selectedAppointmentId}
                    setLocationId={setLocationId}
                    providers={providers}
                    headline='Selected Appointment:' />
            }
            <h3 className="title">{title}</h3>
            <span className="subtitle required-text">*Fields are required</span>
            <hr />
            <div>
                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <label className="label">First Name: (Legal)*</label>
                            <input
                                className={`input ${firstNameDirty && userContactEntityErrors.firstName ? 'is-danger' : ''}`}
                                data-test='firstName'
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="firstName"
                                value={firstName}
                                placeholder="First Name"
                                required
                                type="text"/>
                        </div>
                        <div className="field">
                            <label className="label">Last Name: (Legal)*</label>
                            <input
                                className={`input ${lastNameDirty && userContactEntityErrors.lastName ? 'is-danger' : ''}`}
                                data-test='lastName'
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="lastName"
                                value={lastName}
                                placeholder="Last Name"
                                required
                                type="text"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <label className="label">Date of Birth: (MM/DD/YYYY)*</label>
                            <input
                                className={`input ${dobDirty && userContactEntityErrors.dob ? 'is-danger' : ''}`}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="dob"
                                value={dob}
                                placeholder="DOB (MM/DD/YYYY)"
                                minLength="10"
                                maxLength="10"
                                required
                                type="text"/>
                        </div>
                        <div className="field">
                            <label className="label">Sex: (Legal)*</label>
                            <div className="select">
                                <select
                                    className={`select ${sexDirty && userContactEntityErrors.sex ? 'is-danger' : ''} ${sex !== '' ? 'has-value' : ''}`}
                                    onChange={(e) => {handleInputChange(e);}}
                                    onBlur={(e) => {handleInputBlurUser(e);}}
                                    name="sex"
                                    required
                                    value={sex}>
                                        <option value="" disabled hidden>Please select</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <label className="label">Email:*</label>
                            <input
                                className={`input ${emailDirty && userContactEntityErrors.email ? 'is-danger' : ''}`}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="email"
                                value={email}
                                placeholder="Email"
                                required
                                type="email"/>
                        </div>
                        <div className="field">
                            <label className="label">Phone Number:*</label>
                            <input
                                className={`input ${phoneNumberDirty && userContactEntityErrors.phoneNumber ? 'is-danger' : ''}`}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="phoneNumber"
                                value={phoneNumber}
                                placeholder="Phone Number"
                                minLength="10"
                                maxLength="15"
                                required
                                type="tel"/>
                        </div>
                        <div className="field">
                            <label className="label">Phone Type:*</label>
                            <div className="select">
                                <select
                                    className={`select ${phoneNumberTypeDirty && userContactEntityErrors.phoneNumberType ? 'is-danger' : ''} ${phoneNumberType !== '' ? 'has-value' : ''}`}
                                    onChange={(e) => {handleInputChange(e);}}
                                    onBlur={(e) => {handleInputBlurUser(e);}}
                                    name="phoneNumberType"
                                    value={phoneNumberType} >
                                    <option value="" disabled hidden>Please select</option>
                                    <option value="Home">Home</option>
                                    <option value="Mobile">Mobile</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="field">
                            <label className="label">Address:*</label>
                            <input
                                className={`input ${addressDirty && userContactEntityErrors.address ? 'is-danger' : ''}`}
                                value={address}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="address"
                                placeholder="Address"
                                required
                                type="text"/>
                        </div>
                        <div className="field">
                            <label className="label">Address Cont:</label>
                            <input
                                className={'input'}
                                value={address2}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="address2"
                                placeholder="Address Cont"
                                type="text"/>
                        </div>
                        <div className="field">
                            <label className="label">City:*</label>
                            <input
                                className={`input ${cityDirty && userContactEntityErrors.city ? 'is-danger' : ''}`}
                                value={city}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="city" 
                                placeholder="City"
                                required
                                type="text"/>
                        </div>
                        <div className="field">
                            <label className="label">State:*</label>
                            <div className="select">
                                <select
                                    className={`select ${stateDirty && userContactEntityErrors.state ? 'is-danger' : ''} ${state !== '' ? 'has-value' : ''}`}
                                    onChange={(e) => {handleInputChange(e);}}
                                    onBlur={(e) => {handleInputBlurUser(e);}}
                                    name="state"
                                    value={state}>
                                        <option value="" disabled hidden>Please select</option>
                                        {
                                            Object.entries(STATE_CODES).map(([key, value]) => (
                                                <option
                                                    key={`vmd-state-select-option-${key}`}
                                                    value={key}>
                                                    {value}
                                                </option>
                                            ))
                                        }
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">ZIP*</label>
                            <input
                                className={`input ${zipDirty && userContactEntityErrors.zip ? 'is-danger' : ''}`}
                                onChange={(e) => {handleInputChange(e);}}
                                onBlur={(e) => {handleInputBlurUser(e);}}
                                name="zip"
                                value={zip}
                                placeholder="Zip Code"
                                required
                                type="number"/>
                        </div>
                    </div>
                </div>
                {
                    showGuardianCheck ? guardianQuestion : <div className="guardian-spacer"/>
                }
                <hr />
                <div className="field">
                    <button
                        data-test="next-step-button"
                        className="button is-primary-button is-pulled-right"
                        onClick={() => {
                            handleUserSubmit();
                        }}>Review details</button>
                </div>
            </div>
        </div>
    );
};

export default BookAppointmentUserDetails;
