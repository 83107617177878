import React from 'react';

import BookAppointmentCheckout from '../BookAppointmentCheckout/book_appointment_checkout';

const BookAppointmentSuccess = (props) => {
    const {appointment, appointmentsFilter, appointmentNotes, appointmentInsurance, departments, handleClosePage, providers, reasons, user, setLocationId, queryStringValues} = props;
    const {parsedMobileApp} = queryStringValues;
    const mobileApp = (queryStringValues && parsedMobileApp) ? parsedMobileApp : false;

    return (
        <div className="vmd-book-appointment-success">
            <BookAppointmentCheckout
                appointment={appointment}
                appointmentsFilter={appointmentsFilter}
                appointmentNotes={appointmentNotes}
                appointmentInsurance={appointmentInsurance}
                departments={departments}
                providers={providers}
                reasons={reasons}
                user={user}
                setLocationId={setLocationId}
                title={'Appointment successfully booked'}/>
            {
                mobileApp && (
                    <div>
                        <hr />
                        <div className="field">
                            <button
                                data-test="next-step-button"
                                className="button is-info is-pulled-right"
                                onClick={() => {
                                    handleClosePage();
                                }}>Close</button>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default BookAppointmentSuccess;
