import React from 'react';
import '@stripe/stripe-js';
import * as Sentry from '@sentry/browser';
import {datadogRum} from '@datadog/browser-rum';
import Countly from 'countly-sdk-web';
import {VMDFooter, VMDHeader} from 'vlibrary';

import 'vlibrary/index.css';
import './App.css';

import BookAppointmentCheckout from './components/BookAppointmentCheckout/book_appointment_checkout';
import BookAppointmentComponent from './components/BookAppointmentComponent/book_appointment_component';
import HomePage from './pages/HomePage.js';
import MaintenancePage from './pages/MaintenancePage.js';
import ConfirmationPageSection from './pages/ConfirmationPageSection';

import PaymentForm from './shared/PaymentForm';

import CONSTANTS from './CONSTANTS';

const {DEPARTMENT_ID, APP_ENV, TESTOBJECT} = CONSTANTS;
const {testAppointmentData, testDepartmentId, testDepartments, testProviders, testReasons, testUser} = TESTOBJECT;

function App() {
    let environment = APP_ENV;
    Sentry.init({
        dsn: 'https://5111302758e641afb597ce9d06f7a97b@sentry.io/5178748',
        environment,
    });
    datadogRum.init({
        applicationId: '8c8475c1-9f9d-4d12-a904-1e2477dad1c4',
        clientToken: 'pub56bb754ae2d1b0b084c788610e49c6f8',
        datacenter: 'us',
        resourceSampleRate: 100,
        sampleRate: 100
    });
    datadogRum.addRumGlobalContext('environment', environment);

    Countly.init({
        app_key: 'd3df92b60f1b049667f5ae5340dc4f84607adac7',
        url: 'https://us-try.count.ly',
        remote_config: true,
    });
    Countly.track_sessions();
    Countly.track_pageview();
    Countly.track_clicks();
    Countly.track_scrolls();
    Countly.track_links();
    Countly.track_forms();
    Countly.collect_from_forms()
    Countly.track_errors()

    const queryString = new URLSearchParams(window.location.search.slice(1));
    const maintenance_page = Countly.get_remote_config('maintenance_page') || {};

    // This needs to be filtered regardless, defaulting to telehealth for now
    var parsedLocation = DEPARTMENT_ID; // queryString.get('location'); memorial-1
    var parsedProvider = queryString.get('provider');
    var parsedFirstTimePatient = 'true'; // queryString.get('firstTimePatient');

    const paymentStatus = queryString.get('payment-verification');
    const email = queryString.get('email');
    let user = {
        email: email
    };

    const parsedReason = queryString.get('reason');
    const parsedDate = queryString.get('date');

    const parsedMobileApp = queryString.get('mobileApp');
    const parsedHideHubspot = queryString.get('hideHubspot');
    const parsedHideBranding = queryString.get('hideBranding');
    const parsedHideAllButCheckout = queryString.get('hideAllButCheckout');

    const secretMode = queryString.get('secret-mode');

    const stepChangeCallback = (stepChangeObject) => {
        if (window.webkit) {
            window.webkit.messageHandlers.mobileListener.postMessage(stepChangeObject);
        }
    };
    const successfulCallback = (successfulBookingObject) => {
        if (window.webkit) {
            window.webkit.messageHandlers.mobileListener.postMessage(successfulBookingObject);
        }
    };
    const closeCallback = (closeObject) => {
        if (window.webkit) {
            window.webkit.messageHandlers.mobileListener.postMessage(closeObject);
        }
    };

    const queryStringValues = {
        parsedDate,
        parsedLocation,
        parsedProvider,
        parsedReason,
        parsedFirstTimePatient,
        parsedMobileApp,
        parsedHideHubspot,
        parsedHideBranding
    };

    let pageState;

    if (secretMode !== null) {
        pageState = secretMode;
    } else if (paymentStatus !== null) {
        pageState = 'confirmation';
    } else if (maintenance_page.show === true && (maintenance_page.environment === environment || maintenance_page.environment.includes(environment))) {
        pageState = 'maintenance';
    } else {
        pageState = 'default';
    }

    return (
        <>
            {
                parsedHideBranding !== 'true' && <VMDHeader/>
            }
            {
                (pageState === 'maintenance') && <MaintenancePage MaintenanceMessage={maintenance_page.message}/>
            }
            {
                (pageState === 'default' && parsedHideAllButCheckout !== 'true') && <HomePage/>
            }
            {
                (pageState === 'default' && parsedHideAllButCheckout !== 'true') &&
                <div className={`section ${parsedHideBranding !== 'true' ? 'vmd-branding' : ''}`}>
                    <div id="book-appointment-component" className="container">
                        <h2 style={{marginTop: '1rem', textAlign: 'center'}}>Schedule a virtual visit</h2>
                        <BookAppointmentComponent
                            queryStringValues={queryStringValues}
                            successfulCallback={successfulCallback}
                            closeCallback={closeCallback}
                            stepChangeCallback={stepChangeCallback}/>
                    </div>
                </div>
            }
            {
                (parsedHideAllButCheckout === 'true') &&
                <div className={`section ${parsedHideBranding !== 'true' ? 'vmd-branding' : ''}`}>
                    <div style={{paddingTop: '40px'}} id="book-appointment-component" className="container">
                        <div className="vmd-book-appointment-component">
                            <div>
                                <div className="container">
                                    <BookAppointmentCheckout
                                        appointment={testAppointmentData}
                                        appointmentsFilter={'2'}
                                        appointmentNotes={'These are some notes.'}
                                        appointmentInsurance={'This is insurance'}
                                        departments={testDepartments}
                                        providers={testProviders}
                                        reasons={testReasons}
                                        user={testUser}
                                        setLocationId={testDepartmentId}
                                        buttonClickCallback={() => {
                                            // console.log('callback triggered')
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                (pageState === 'payment' && parsedHideAllButCheckout !== 'true') &&
                <div
                    className={`section is-marginless is-paddingless ${parsedHideBranding !== 'true' ? 'vmd-branding' : ''}`}>
                    <div style={{padding: '25vh'}} id="payment-form-component" className="container">
                        <div className="columns is-centered">
                            <div className="column is-three-fifths">
                                <PaymentForm user={user}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                (pageState === 'confirmation' && parsedHideAllButCheckout !== 'true') &&
                <ConfirmationPageSection paymentVerification={paymentStatus}/>
            }
            {
                parsedHideBranding !== 'true' &&
                <VMDFooter environment={environment}/>
            }
        </>
    );
}

export default App;
