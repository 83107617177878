import moment from 'moment-timezone';

export const getAppointmentLocalTime = (
    date,
    time,
    timezone = 'America/Chicago',
    format = 'MM/DD/YYYYTHH:mm',
    outputFormat = 'h:mm a',
) => {
    let returnString = 'Invalid Time';

    if (date && time) {
        returnString = moment.tz(`${date}T${time}`, format, timezone).local().format(outputFormat);
    }

    return returnString;
};
