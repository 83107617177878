import React from 'react';
import moment from 'moment-timezone';

import './book_appointment_selected_appointment.scss';

import {getAppointmentLocalTime} from '../../utilities';

const BookAppointmentSelectedAppointment = (props) => {
    const {appointment, appointmentsFilter, departments, handleDismissAppointment, hideCloseButton, reasons, setLocationId, success, providers, headline} = props;
    const {date, starttime, providerid} = appointment;
    const provider = providers.find((p) => parseInt(p.providerid, 10) === parseInt(providerid, 10));
    const reason = reasons.find((r) => parseInt(r.reasonid, 10) === parseInt(appointmentsFilter, 10));
    const department = departments.find((d) => parseInt(d.departmentid, 10) === parseInt(setLocationId, 10));
    const {timezonename} = department;
    const timezoneNameFull = moment.tz.guess().replace(/_/, ' ');
    const timezoneNameShort = moment().tz(moment.tz.guess()).format('z');
    const localHourMinuteDate = getAppointmentLocalTime(date, starttime, timezonename);

    return (
        <div className={`selected-appointment ${success ? 'success' : ''}`}>
            <span className="headline">{headline ? headline : ''}</span>
            <h4 className="title" data-test="appointment-information-reason">{(reason && reason.reason) && reason.reason}</h4>
            {
                !hideCloseButton &&
                    <i
                        className="delete is-pulled-right cancel-appointment"
                        data-test="dismiss-appointment"
                        onClick={() => handleDismissAppointment && handleDismissAppointment()} />
            }
            <div className="columns">
                <div className="column">
                    <span className="label">Provider</span>
                    <div className="is-flex">
                        <div>
                            <div className="provider-photo">
                                {
                                    <img
                                        src={provider && provider.hubspotImage ? provider.hubspotImage : 'https://cdn2.hubspot.net/hubfs/6509886/default-profile.png?width=100'}
                                        alt={`Village Medical - ${provider.lastname}, ${provider.firstname} ${provider.providertype ? provider.providertype : ''}`}/>
                                }
                            </div>
                        </div>
                        <div data-test="provider-name">
                            {
                                (provider.hasOwnProperty('firstname') && provider.hasOwnProperty('lastname')) ? (provider.lastname + ', ' + provider.firstname + ' ' + (provider.hasOwnProperty('providertype') ? provider.providertype : '')) : 'No provider details'
                            }
                        </div>
                    </div>
                </div>
                <div className="column">
                    <span className="label">Time</span>
                    <span data-test="appointment-information-time">{localHourMinuteDate}</span> on <span data-test="appointment-information-date">{moment(date, 'MM/DD/YYYY').format('dddd, MMM Do YYYY') }</span>
                    <p data-test="appointment-information-timezone">Timezone: {timezoneNameShort} ({timezoneNameFull})</p>
                </div>
            </div>
        </div>
    );
};

export default BookAppointmentSelectedAppointment;
