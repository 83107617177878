import React from 'react';

import './vmd_billboard.scss';

export default class VirtualCareBillboard extends React.Component {
    render() {
        return (
            <section className="is-small billboard-section is-flex">
                <div>
                    <div className="billboard-section-container">
                        <h1 style={{color: '#14345C'}}>Primary Care Virtual Visits</h1>
                        <p className="subtitle">See a doctor on your computer or smartphone, at home or on the go.</p>
                    </div>
                    <a href="#book-appointment-component" style={{background: 'rgb(20,116,150)'}} className="button is-large is-info">
                        <i style={{paddingRight: '8px'}} className="fas fa-video"/>
                        Find Appointments
                    </a>
                </div>
            </section>
        );
    }
}
