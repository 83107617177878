import Axios from 'axios';
import CONSTANTS from './CONSTANTS';

const {API_HUBSPOT_URL} = CONSTANTS;

/*
* Get Methods
*/

/* exported getProviderDetails */
export async function getProviderDetails (firstName, lastName) {
    const endpointParams = `&Name__eq=${firstName}&last_name__eq=${lastName}`;
    const updatedAPILink = `${API_HUBSPOT_URL}${endpointParams}`;

    return await Axios.get(updatedAPILink);
}