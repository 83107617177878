import React from 'react';
import moment from 'moment-timezone';
import PaymentForm from '../../shared/PaymentForm';

import videoIcon from '../../component-assets/svg/video_icon.svg';
import './book_appointment_checkout.scss';

import {getAppointmentLocalTime} from '../../utilities';
import CONSTANTS from '../../CONSTANTS';

const {APPOINTMENT_PRICE} = CONSTANTS;

const BookAppointmentCheckout = (props) => {
    const retailPrice = APPOINTMENT_PRICE;
    const {appointment, buttonText, departments = [], providers = [], user, setLocationId} = props;
    const {date, starttime, providerid} = appointment;
    const provider = providers.find((p) => parseInt(p.providerid, 10) === parseInt(providerid, 10));
    const department = departments.find((d) => parseInt(d.departmentid, 10) === parseInt(setLocationId, 10));
    const {timezonename} = department;
    const timezoneNameFull = moment.tz.guess().replace(/_/, ' ');
    const timezoneNameShort = moment().tz(moment.tz.guess()).format('z');

    const localHourMinuteDate = getAppointmentLocalTime(date, starttime, timezonename);

    return (
        <div className="vmd-book-appointment-checkout">
            <div className="columns">
                <div className="column">
                    <h3 className="title is-spaced">Appointment Confirmed</h3>
                    <span className="subtitle">Please click {buttonText ? buttonText : '"Pay Now"' } below to submit payment prior to your appointment.</span>
                    <div className="headline-checkout is-flex">
                        <img className="headline-icon" src={videoIcon} alt="Video Visit" />
                        <span className="headline-text">
                            This is a virtual visit. Your provider will send you a link to access the appointment via email or text message shortly before the visit.
                        </span>
                    </div>
                </div>
            </div>
            <div className="columns checkout-form-wrapper">
                <div className="column">
                    <span className="label">Provider</span>
                    <div className="is-flex provider-container">
                        <div className="provider-photo">
                        {
                            (provider && provider.lastname && provider.firstname) &&
                            <img
                                src={provider.hubspotImage ? provider.hubspotImage : 'https://cdn2.hubspot.net/hubfs/6509886/default-profile.png?width=100'}
                                alt={`Village Medical - ${provider.lastname}, ${provider.firstname} ${provider.providertype ? provider.providertype : ''}`}
                                data-test="provider-image"/>
                        }
                        </div>
                        <span data-test="provider-information">
                            {
                                (provider && provider.firstname && provider.lastname) ? (provider.lastname + ', ' + provider.firstname  + (provider.providertype ? ` ${provider.providertype}` : '')) : 'No provider details'
                            }
                        </span>
                    </div>
                </div>
                <div className="column">
                    <span className="label">Time</span>
                    <span data-test="appointment-information-datetime">{localHourMinuteDate} on {moment(date, 'MM/DD/YYYY').format('dddd, MMM Do YYYY') }</span>
                    <p data-test="appointment-information-timezone">Timezone: {timezoneNameShort} ({timezoneNameFull})</p>
                </div>
                <div className="column is-2">
                    <span className="label">Price</span>
                    <span><strong data-test="appointment-price">{retailPrice}</strong></span>
                </div>
                <PaymentForm user={user} />
            </div>
        </div>
    );
};

export default BookAppointmentCheckout;
