import React from 'react';
import './home.scss';

import VirtualCareBillboard from '../shared/VirtualCareBillboard.js';

export default class HomePage extends React.Component {
    render() {
        const modalClassState = 'modal';

        return (
            <main className="app-content">
                <div className={modalClassState}  style={{borderRadius: '30px'}}>
                    <div className="modal-background"/>
                    <div className="modal-content"/>
                    <button onClick={this.toggleModal} className="modal-close is-large" aria-label="close"/>
                </div>
                <div id="availability">
                    <VirtualCareBillboard />
                </div>
                <section className="app-section">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-four-fifths">
                                <h2>The care you need with the convenience you want</h2>
                                <p>Virtual visits are available for routine appointments, medication renewal
                                    requests or if you’re unwell. See a local Village Medical physician, physician assistant
                                    or nurse practitioner when and where it is most convenient for you.
                                </p>
                                <div className="checklist">
                                    <ul>
                                        <li>
                                            <i className="fas fa-2x fa-check-circle color-green-light"/>
                                            <span>See a Village Medical doctor from the safety and comfort of your own home</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-2x fa-check-circle color-green-light"/>
                                            <span>No special software, apps or passwords needed</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-2x fa-check-circle color-green-light"/>
                                            <span>Same-day appointments available</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="app-section color-white-bluish-dark-bg">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-four-fifths">
                                <h2>Care is a click away</h2>
                                <p>
                                    Once your appointment is scheduled, you'll receive a text or email link 15-30 minutes
                                    before your scheduled appointment.  One click opens the virtual waiting room, where your
                                    provider will join you at your scheduled start time.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
