import React, {useEffect, useRef} from 'react';
import moment from 'moment-timezone';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import CONSTANTS from '../../CONSTANTS';

import BookAppointmentListSection from '../BookAppointmentListSection/book_appointment_list_section';
import BookAppointmentHTimeControl from '../BookAppointmentHTimeControl/book_appointment_H_time_control';
import BookAppointmentLoadingStatus from '../BookAppointmentLoadingStatus/book_appointment_loading_status';

import './book_appointment_list.scss';

const {STATE_TO_MARKET_LOOKUP, MARKET_TO_DISPLAYNAME_LOOKUP} = CONSTANTS;

const BookAppointmentList = (props) => {
    const {appointments, appointmentsFilter, dateSpan, departments, expandedDates,
        handleFilterReason, handleSelectAppointment, handleSelectDepartment, handleUserTypeChange,
        handleExpansionDateColumn, handleUpdateDate, handleUpdateStartDate, isMobile, reasons,
        providers, setLocationId, setFirstTimePatient, startDate, componentIsLoading, showDatePicker
    } = props;
    const appointmentsToUse = appointments.slice(0);
    let datepickerRef = useRef(null);

    useEffect(() => {
        datepickerRef.setOpen && datepickerRef.setOpen(showDatePicker);
    }, [showDatePicker]);

    const sortDepartmentsByState = (a,b) => a.state < b.state ? -1 : a.state > b.state ? 1 : 0;

    /* exported locationDropDownSeperator */
    // 
    // Linter is not looking into the render function to see that this variable IS being used
    // TODO: make sure our linting rules are correctly applied to a react project
    //
    // eslint-disable-next-line
    let locationDropDownSeperator = '';
    
    const departmentObject = departments.find((d) => {
        return parseInt(d.departmentid, 10) === parseInt(setLocationId, 10);
    });

    const appointmentGridData = () => {
        const pluckedDates = [];
        const pluckedProviders = [];
        const newStructure = {};

        appointmentsToUse.forEach((a) => {
            if (!pluckedDates.includes(a.date)) {
                pluckedDates.push(a.date);
            }
            if (!pluckedProviders.includes(a.providerid)) {
                pluckedProviders.push(a.providerid);
            }
        });

        pluckedProviders.forEach((p) => {
            newStructure[`${p}`] = {};

            pluckedDates.forEach((date) => {
                newStructure[`${p}`][`${date}`] = appointmentsToUse.filter((a) => (a.date === date && a.providerid === p));
            });
        });

        return {newStructure, pluckedDates};
    };
    
    const getProviderObject = (providerId) => {
        return providers.find((p) => {
            return parseInt(p.providerid, 10) === parseInt(providerId, 10);
        });
    };
    const reasonToDisplay =  appointmentsFilter ? reasons.find((reason) => reason.reasonid === parseInt(appointmentsFilter, 10)) : null;    
    const {newStructure} = appointmentGridData();

    let sectionWrapper = [];
    let index = 0;
    let datesToUse = [];
    let datesToUseCounter = 0;
    
    if (isMobile) {
        datesToUse.push(startDate);
    } else {
        while (datesToUseCounter < dateSpan) {
            let day = moment(startDate).startOf('day').add(datesToUseCounter, 'days').format('MM/DD/YYYY');
            datesToUse.push(day);
            datesToUseCounter += 1;
        }
    }

    // eslint-disable-next-line
    for (let provider in newStructure) {
        sectionWrapper[`${index}`] = (
            <BookAppointmentListSection
                dates={datesToUse}
                data={newStructure[`${provider}`]}
                departmentObject={departmentObject}
                expandedDates={expandedDates}
                handleSelectAppointment={handleSelectAppointment}
                handleExpansionDateColumn={handleExpansionDateColumn}
                isMobile={isMobile}
                provider={getProviderObject(provider)}
                key={`list-section-${index}`} />
            )

        index += 1;
    }
    // else there are no appointments available for the whole page - so lets show a button that sees all appts

    return (
        <div className="vmd-book-appointment-list">
            <div className="filter-location-section">
                <div className="columns">
                    <div className="column is-hidden">
                        <div className="control ">
                            <label className="label">Clinic:</label>
                            <div className="select">
                                <select
                                    className={`select vmd-location-select ${setLocationId !== '' ? 'has-value' : ''}`}
                                    data-test="appointment-list-location-select"
                                    name="setLocationId"
                                    onChange={(e) => {
                                        handleSelectDepartment(e);
                                    }}
                                    value={setLocationId}>
                                    <option value="" disabled hidden>Please select a clinic</option>
                                    {
                                        departments.length > 0 && departments.sort(sortDepartmentsByState).reverse().map((department, index) => {
                                            let departmentName = `${department.patientdepartmentname ? department.patientdepartmentname : ''}    ${department.address ? department.address : ''} ${department.address2 ? department.address2 : ''}. ${department.city ? department.city : ''}, ${department.state ? department.state : ''}, ${department.zip ? department.zip : ''}`;
                                            let returnedElement;
                                            let optionalElement;
                                            let market =  STATE_TO_MARKET_LOOKUP[`${department.state}`];

                                            if (locationDropDownSeperator !== market) {
                                                locationDropDownSeperator = market;
                                                optionalElement = (<option disabled>{MARKET_TO_DISPLAYNAME_LOOKUP[`${locationDropDownSeperator}`]}</option>);
                                            }

                                            returnedElement = (
                                                <React.Fragment
                                                    key={`vmd-location-select-option-group-${index}`}>
                                                    {optionalElement}
                                                    <option
                                                        key={`vmd-location-select-option-${index}`}
                                                        value={department.departmentid}>
                                                        {departmentName}
                                                    </option>
                                                </React.Fragment>
                                            );

                                            return (
                                                returnedElement
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="column is-2">
                        <div className="control">
                            <label className="label">First-time patient?:</label>
                            <div className="select">
                                <select
                                    className={`select vmd-location-select ${setFirstTimePatient ? 'has-value' : ''}`}
                                    data-test="appointment-list-user-type-select"
                                    name="setFirstTimePatient"
                                    onChange={(e) => {
                                        handleUserTypeChange(e);
                                    }}
                                    value={setFirstTimePatient}>
                                        <option value="" disabled hidden>Please select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="control">
                            <label className="label">Appointment type:</label>
                            <div className="select">
                                <select
                                    className={`select vmd-location-select ${appointmentsFilter !== '' ? 'has-value' : ''}`}
                                    data-test="appointment-list-appointment-type-select"
                                    name="setFilterReason"
                                    onChange={(e) => {
                                        handleFilterReason(e);
                                    }}
                                    value={appointmentsFilter} >
                                    <option value="" hidden disabled>Please select an appointment reason</option>
                                    {
                                        reasons && reasons.length > 0 && reasons.map((reason, index) => {
                                            return (
                                                <option
                                                    key={`filter-reason-${index}`}
                                                    value={reason.reasonid}>{reason.reason}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <label className="label">Date:</label>
                        <div className="vmd-book-appointment-list-datepicker">
                            {
                                startDate &&
                                <DatePicker
                                    calendarClassName="vmd-book-appointment-list-datepicker-calendar"
                                    className="vmd-book-appointment-list-datepicker-input input"
                                    dateFormat="MM/dd/yyyy"
                                    minDate={moment().toDate()}
                                    selected={moment(startDate).toDate()}
                                    ref={(r) => datepickerRef = r}
                                    onChange={handleUpdateStartDate} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <BookAppointmentHTimeControl
                dateSpan={dateSpan}
                handleUpdateDate={handleUpdateDate}
                isMobile={isMobile}
                startDate={startDate} />
            {
                (componentIsLoading) && <BookAppointmentLoadingStatus />
            }
            {
                (componentIsLoading === false && sectionWrapper.length > 0) &&  sectionWrapper
            }
            {
                (componentIsLoading === false && sectionWrapper.length === 0) && <div className="has-text-centered"><p><strong>No Appointments for these specific times for this location and provider {reasonToDisplay !== null ? ` -- or for this ${reasonToDisplay && reasonToDisplay.hasOwnProperty('reason') ? reasonToDisplay.reason : ''} Appointment type.` : '.'}</strong></p></div>
            }
        </div>
    );
};

export default BookAppointmentList;
